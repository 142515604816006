@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
body {
  margin: 0;
  background-color: "#2196f3";
  font-family: "cinzel";
  background-color:"#fffff0";
 
}


